import React from "react"
import { Link } from "gatsby"

import icon from "../images/overviewer-icon-rounded.png"

const Home = () => (
  <div style={{ textAlign: "center", marginTop: "40px", display:"flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
    <div style={{ height: "130px", marginBottom: "40px" }}>
      <Link to="/">
        <img src={icon} height="100%" width="100%" style={{ objectFit: "contain" }} />
      </Link>
    </div>
    <h1>Help</h1>
    <div style={{ marginBottom: "60px", maxWidth: "800px" }}>
        <p>
            I've created a couple tutorial videos to show how to use Overviewer to turn your iPhone into a document camera. 
        </p>
        <h2 style={{ marginTop: "60px" }}>Overviewer with Zoom</h2>
        <p>
            This video shows you how the Overviewer app works and how you can share it using Zoom.
        </p>
        <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto" }}>
            <div className="postYoutube">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/fadOMMpQErg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;  picture-in-picture; fullscreen" allowfullscreen></iframe>
            </div>
        </div>
        <h2 style={{ marginTop: "60px" }}>Not Using Zoom?</h2>
        <p>
            If you're using a video conferencing app other than Zoom such as Google Meet or Microsoft Teams this video shows how to use Overviewer using Quicktime on a Mac or <a href="https://www.airsquirrels.com/reflector">Reflector</a> on Windows.
        </p>
        <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto" }}>
            <div className="postYoutube">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/mtt2EKgnH0Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;  picture-in-picture; fullscreen" allowfullscreen></iframe>
            </div>
        </div>
    </div>
  </div>
)

export default Home
